import {AjaxSync} from "../shared/ajax_utilities";

$(() => {
    $('a.btn-reprocess').click((e) => {
        e.preventDefault();
        const $button = $(e.target).parent();
        const file_id = $button.data('row-id');
        refresh_queue(file_id, 'reprocess', () => {
            $button.fadeOut();
        })
    });

    $('a.btn-clear-errors').click((e) => {
        e.preventDefault()
        const $button = $(e.target).parent()
        const file_id = $button.data('row-id')
        refresh_queue(file_id, 'clear_error', () => {
            window.location.reload()
        });
    });
});

function refresh_queue(file_id, action, nextAction)
{
    const url = app.CACHE.URL_ADMIN + 'tco_que/' + file_id + '/' + action;
    AjaxSync({
        url,
        method: 'GET',
        dataType: 'json',
    }, {
        done: nextAction,
    });
}